import React, { useEffect, useState } from "react";

export default function Entry() {
  const [exportComponent, setExportComponent] = useState(null);
  useEffect(() => {
    import(`./Drive`).then((Export) => {
      setExportComponent(Export);
    });
  }, []);
  return exportComponent ? <exportComponent.default /> : null;
}
