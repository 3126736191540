import React, { useState, useEffect, useCallback } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useLastError } from "../features/api/msApiSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MsApiLastErrorSnackBase({ lastError }) {
  //const theme = useTheme();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (lastError) {
      setOpen(true);
    }
  }, [lastError]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Snackbar open={open} onClose={onClose}>
      <Alert onClose={onClose} severity="error">
        {lastError}
      </Alert>
    </Snackbar>
  );
}

export default function MsApiLastErrorSnack() {
  const lastError = useLastError();
  return <MsApiLastErrorSnackBase lastError={lastError} />;
}
