import { useEffect, useMemo, useState, useCallback } from "react";

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { useGetCurrentStoreId } from "../../components/Drive/SideEffects/Store";

import { axiosGetOneCache } from "./api";
import {
  defaultStoreId,
  enableAutoplay,
  formatStoreId,
  getApiUrls,
} from "./constants";
import sluggize from "./sluggize";

const contentAdapter = createEntityAdapter();

const contentFetchers = {};
export const fetchContent = createAsyncThunk(
  "drive/fetchContent",
  async ({ id } = {}, { getState, dispatch }) => {
    if (!id) {
      throw new Error("invalid parameter");
    }
    if (contentFetchers[id]) {
      return await contentFetchers[id];
    }
    contentFetchers[id] = (async () => {
      return (await axiosGetOneCache(`/json/cms/${id}.json`)).data;
    })();
    try {
      return await contentFetchers[id];
    } finally {
      delete contentFetchers[id];
    }
  }
);

export const slice = createSlice({
  name: "cms",
  initialState: {
    pending: 0,
    contentPending: {},
    contents: contentAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: {
    [fetchContent.pending]: (
      state,
      {
        meta: {
          arg: { id },
        },
      }
    ) => {
      state.pending++;
      state.contentPending[id] = (state.contentPending[id] || 0) + 1;
    },
    [fetchContent.fulfilled]: (state, action) => {
      const {
        payload: data,
        meta: {
          arg: { id },
        },
      } = action;
      state.pending--;
      state.contentPending[id] = (state.contentPending[id] || 0) - 1;
      if (data) {
        contentAdapter.upsertOne(state.contents, { id, data });
      }
    },
    [fetchContent.rejected]: (
      state,
      {
        meta: {
          arg: { id },
        },
      }
    ) => {
      state.pending--;
      state.contentPending[id] = (state.contentPending[id] || 0) - 1;
    },
  },
});

const { /*actions,*/ reducer } = slice;
export default reducer;

export const {
  selectTotal: selectTotalContents,
  selectAll: selectAllContents,
  selectById: selectContentById,
} = contentAdapter.getSelectors((state) => state.drive.cms.contents);

export function selectIsContentPending(state, id) {
  return !!state.drive.cms.contentPending[id];
}

export function filterActiveContent({ content, storeId = "default" }) {
  const now = new Date();
  storeId = formatStoreId(storeId);

  // If no content exists for the store in the current date range we explecitly
  // return the first content found: it should be a error configuration in back office
  const prefilteredContent = content
    .filter(
      ({ StartAt, EndAt }) => new Date(StartAt) <= now && new Date(EndAt) >= now
    )
    .filter(
      ({ ExceptStoreIds = [] }) => ExceptStoreIds.indexOf(storeId) === -1
    );

  const filteredContent = prefilteredContent.filter(
    ({ OnlyStoreIds }) => !OnlyStoreIds || OnlyStoreIds.indexOf(storeId) >= 0
  );

  if (filteredContent.length === 0 && prefilteredContent.length > 0) {
    return [prefilteredContent[0]];
  }
  return filteredContent;
}

export function selectActiveContentById(state, { id, storeId }) {
  const content = selectContentById(state, id);

  return content
    ? filterActiveContent({
        content: content.data,
        storeId: storeId,
      })
    : null;
}

export function useContent(id) {
  const dispatch = useDispatch();
  const selector = useGetCurrentStoreId();
  const storeId = useMemo(() => formatStoreId(selector), [selector]);
  const content = useSelector((state) =>
    selectActiveContentById(state, { id, storeId })
  );
  const [hasDispatched, setHasDispatched] = useState(false);
  const isPending = useSelector((state) => selectIsContentPending(state, id));

  useEffect(() => {
    if (!content && !hasDispatched && !isPending) {
      setHasDispatched(true);
      dispatch(fetchContent({ id }));
    }
  }, [dispatch, id, content, hasDispatched, isPending]);

  return content;
}

export function useStores() {
  const content = useContent("stores");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content?.[0]?.Stores || [];
  }, [content]);
}

export function useSliders() {
  const content = useContent("sliders");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content?.[0]?.Sliders || [];
  }, [content]);
}

export function useSlidersAutoplay() {
  const content = useContent("sliders");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    if (!enableAutoplay) {
      return false;
    }
    return content?.[0]?.Autoplay;
  }, [content]);
}

export function useSlidersDuration() {
  const content = useContent("sliders");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content?.[0]?.Duration;
  }, [content]);
}

export function useCategoriesCards() {
  const content = useContent("categoriesCards");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content
      .map(({ CategoriesCards }) => CategoriesCards || [])
      .reduce((s, x) => s.concat(x), []);
  }, [content]);
}

export function useCategoriesCardsForCategory({
  categorySlug,
  subcategorySlug,
  subsubcategorySlug,
}) {
  const cards = useCategoriesCards();
  if (cards === null) {
    return null;
  }
  return cards.filter(
    ({ idCard }) => idCard === categorySlug || idCard === "*"
  );
}

export function useHomepageCards() {
  const content = useContent("homepageCards");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content?.[0]?.HomepageCards || [];
  }, [content]);
}

export function useGenericPages() {
  const content = useContent("genericPages");

  return useMemo(
    () =>
      !content
        ? null
        : content.reduce(
            (s, x) => ({ ...s.GenericPages, ...(x?.GenericPages || {}) }),
            {}
          ),
    [content]
  );
}

export function useCatalogs() {
  const content = useContent("catalogs");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content?.[0]?.Catalogs || null;
  }, [content]);
}

export function useCategoryDescriptions() {
  const content = useContent("category-description");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content?.[0]?.Descriptions || {};
  }, [content]);
}

export function useIsStoreEnabled(storeIdParam) {
  const currentStoreId = useGetCurrentStoreId();
  const stores = useStores();
  return useMemo(() => {
    const storeId = storeIdParam || currentStoreId;

    if (!storeId) {
      return null;
    }
    if (!stores) {
      return null;
    }

    const store = stores?.find((x) => x.store_id === storeId);
    return store?.enable_click_and_collect === false ? false : true;
  }, [currentStoreId, stores, storeIdParam]);
}

export function useIsStoreValid() {
  const currentStoreId = useGetCurrentStoreId();
  const stores = useStores();
  return useMemo(() => {
    if (!currentStoreId) {
      return null;
    }
    if (!stores) {
      return null;
    }

    if (currentStoreId === defaultStoreId) {
      return false;
    }

    const store = stores?.find((x) => x.store_id === currentStoreId);
    return store ? true : false;
  }, [currentStoreId, stores]);
}

export function useCategoriesRevision() {
  const content = useContent("categories");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return content?.[0]?.Revision || "";
  }, [content]);
}

function parseLayoutCategories(layoutCategoriesBase) {
  return layoutCategoriesBase.map((row, i) => {
    return {
      ...row,
      orderBy: i,
      subcategories: (row.subcategories || []).map(
        ({ label, slug, id, isInMenu, isInLateralMenu, subsubcategories }) => ({
          label,
          id,
          isInMenu,
          isInLateralMenu,
          slug: slug || sluggize(label),
          subsubcategories: subsubcategories?.map(
            ({ label, slug, isInMenu, isInLateralMenu }) => ({
              label,
              slug: slug || sluggize(label),
              isInMenu,
              isInLateralMenu,
            })
          ),
        })
      ),
    };
  });
}

export function useCategories() {
  const content = useContent("categories");

  return useMemo(() => {
    if (!content) {
      return null;
    }
    return parseLayoutCategories(content?.[0]?.Categories || []);
  }, [content]);
}

export function useLandings() {
  const content = useContent("landings");
  return useMemo(() => {
    if (!content) {
      return null;
    }
    return !content
      ? content
      : content
          .map(({ Landings }) => Landings)
          .filter(Boolean)
          .reduce((s, x) => s.concat(x), []);
  }, [content]);
}

export function selectCategories(state, storeId) {
  const content = selectActiveContentById(state, {
    id: "categories",
    storeId: storeId,
  });

  return parseLayoutCategories(content?.[0]?.Categories || []);
}

export function useProductsCustomSort() {
  const storeId = useGetCurrentStoreId();

  const content = useSelector((state) =>
    selectActiveContentById(state, { id: "productsCustomSort", storeId })
  );
  const customSort = content?.[0]?.CustomSort || null;
  /* eslint no-new-func:off */
  return useMemo(
    () =>
      customSort
        ? new Function(["productA", "productB"], `return ${customSort}`)
        : null,
    [customSort]
  );
}

export function selectProductsCustomSortLoaded(state) {
  return !!state.drive.cms.contents.entities.productsCustomSort;
}

export function useProductsCustomSortLoaded() {
  return useSelector(
    useCallback((state) => selectProductsCustomSortLoaded(state), [])
  );
}

export function useSkinNames() {
  const content = useContent("skins");

  return useMemo(
    () =>
      content === null
        ? null
        : content
            .map(({ SkinName, skins }) => SkinName || skins?.[0]?.SkinName)
            .filter(Boolean),
    [content]
  );
}

export function useSkin() {
  const content = useContent("skins");

  return useMemo(() => {
    if (!content) {
      return null;
    }
    let currentSkin = null;
    content.forEach((elem) => {
      const now = new Date().getTime();
      const startAt = new Date(elem.StartAt).getTime();
      const endAt = new Date(elem.EndAt).getTime();
      if (now >= startAt && now <= endAt) {
        currentSkin = elem.skins ? elem.skins : null;
      }
    });
    return currentSkin;
  }, [content]);
}

export function useGetOrders(userId, accessToken) {
  const [data, setData] = useState(null);

  const fetchOrders = useCallback(async () => {
    try {
      const response = await axios.get(getApiUrls().Orders, {
        timeout: 30e3,
        headers: {
          "X-Reachfive-Token": accessToken,
          "X-Gifi-Identifiant": userId,
        },
      });
      if (response.status === 200) {
        setData(response.data.orderIds);
      }
    } catch (error) {
      setData([]);
    }
  }, [accessToken, userId]);
  useEffect(() => {
    if (accessToken && userId) {
      fetchOrders();
    }
  }, [fetchOrders, accessToken, userId]);

  return data;
}

function reducePrice({ cartContents, consentVIP }) {
  const total = Object.entries(cartContents).reduce((s, [, element]) => {
    return element.count > 0
      ? s +
          element.count *
            Number(
              consentVIP ? element.priceVIP || element.price : element.price
            )
      : s;
  }, 0);
  return Math.round(total * 100) / 100;
}

function getItems({ cartContents, consentVIP }) {
  const items = Object.keys(cartContents).map((key) => {
    return {
      price: consentVIP
        ? cartContents[key]?.priceVIP || cartContents[key]?.price
        : cartContents[key]?.price,
      faceValue: cartContents[key]?.faceValue,
      voucherValue: cartContents[key]?.voucherValue,
      count: cartContents[key]?.count,
      item_ref: key,
      cancelled: cartContents[key]?.count === 0,
    };
  });
  return items;
}

export function countItems(items) {
  return items.filter((item) => item.cancelled === true);
}

export async function getOrder(orderId) {
  try {
    const response = await axios.get(getApiUrls().GetOrder, {
      timeout: 30e3,
      params: {
        orderId,
      },
    });
    if (response.status === 200) {
      const {
        consentVIP,
        cartContents,
        orderDisplayId,
        status,
        storeId,
        createdAt,
      } = response.data;

      const price = [{ valeur: reducePrice({ cartContents, consentVIP }) }];
      const items = getItems({ cartContents, consentVIP });
      const articles_quantity = items
        .filter((item) => !item.cancelled)
        .reduce((s, { faceValue, count }) => s + (faceValue ? 0 : count), 0, 0);
      return {
        receipt_id: orderDisplayId,
        status,
        store_id: storeId,
        date: createdAt,
        price,
        articles_quantity,
        items,
      };
    }
  } catch (error) {
    throw error;
  }
}
