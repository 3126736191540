import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import axios from "axios";

import { setLastError } from "../api/msApiSlice";

import { getApiUrls } from "./constants";

const ordersAdapter = createEntityAdapter();

const fetchInProgress = {};

export const fetchOrder = createAsyncThunk(
  "drive/fetchOrder",
  async ({ orderId }, { getState, dispatch }) => {
    if (fetchInProgress[orderId]) {
      return await fetchInProgress[orderId];
    }

    fetchInProgress[orderId] = (async () => {
      let apiResponse;
      try {
        apiResponse = await axios({
          method: "get",
          url: getApiUrls().GetOrder,
          params: {
            orderId,
          },
        });
      } catch (e) {
        return dispatch(setLastError(e.toString()));
      }

      if (!apiResponse) {
        return dispatch(setLastError("Inconnu"));
      }

      return { id: orderId, ...apiResponse.data };
    })();

    try {
      return await fetchInProgress[orderId];
    } finally {
      fetchInProgress[orderId] = null;
    }
  }
);

export const slice = createSlice({
  name: "orders",
  initialState: ordersAdapter.getInitialState({ pending: 0 }),
  reducers: {
    clearOrderCache: (state, action) => {
      ordersAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: {
    [fetchOrder.pending]: (state, action) => {
      state.pending++;
    },
    [fetchOrder.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        ordersAdapter.upsertOne(state, data);
      }
    },
    [fetchOrder.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const { actions, reducer } = slice;
export const { clearOrderCache } = actions;
export default reducer;

export const {
  selectTotal: selectTotalOrders,
  selectAll: selectAllOrders,
  selectById: selectOrderById,
} = ordersAdapter.getSelectors((state) => state.drive.orders);
