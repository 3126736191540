import { memo, useCallback, useEffect, useMemo } from "react";

import { useGetAtom, useAtom, useSetAtom } from "@mobsuccess-devops/react-atom";
import { useData } from "@mobsuccess-devops/react-data-context";

import PropTypes from "prop-types";

import { formatStoreId } from "../../../../features/drive/constants";
import { useGetCurrentStoreId } from "../Store";

const atomStores = (storeId) => `#Drive.VoucherBase.store(${storeId})`;
const atomProducts = (sku) => `#Drive.VoucherBase.sku(${sku})`;
const atomFetchDate = "#Drive.VoucherBase.fetchDate";

function isVoucherEnabled(voucher, fetchDate) {
  if (!voucher) {
    return false;
  }

  const { StartAt, EndAt } = voucher;
  return fetchDate >= StartAt && fetchDate <= EndAt;
}

function useVoucherStoreConfigurations(storeId) {
  storeId = formatStoreId(storeId);
  const [now] = useAtom(atomFetchDate, new Date());

  const [wildcardStoreConfiguration] = useAtom(atomStores("*"), []);

  const [storeConfiguration] = useAtom(atomStores(storeId), []);
  const mergedConfigurations = useMemo(
    () => [...wildcardStoreConfiguration, ...storeConfiguration],
    [wildcardStoreConfiguration, storeConfiguration]
  );

  return mergedConfigurations.filter(({ StartAt, EndAt }) => {
    return StartAt <= now && EndAt >= now;
  });
}

export function useIsVoucherEnabledOnStore(storeId) {
  const filteredConfigurations = useVoucherStoreConfigurations(storeId);
  return filteredConfigurations.length > 0;
}

export function useVoucherAmountOnStore(storeId) {
  const filteredConfigurations = useVoucherStoreConfigurations(storeId);
  return filteredConfigurations.length > 0
    ? filteredConfigurations[0].Amount
    : 0;
}

export function useIsVoucherEnabledOnProduct(sku, withCurrentStore = true) {
  const [voucher] = useAtom(atomProducts(sku));
  const [fetchDate] = useAtom(atomFetchDate, new Date());
  const currentStoreId = useGetCurrentStoreId();
  const isVoucherEnabledOnStore = useIsVoucherEnabledOnStore(currentStoreId);

  return useMemo(() => {
    if (withCurrentStore && !isVoucherEnabledOnStore) {
      return false;
    }

    return isVoucherEnabled(voucher, fetchDate);
  }, [voucher, isVoucherEnabledOnStore, withCurrentStore, fetchDate]);
}

export function useVoucherAmountOnProduct(sku) {
  const isVoucherEnabledOnProduct = useIsVoucherEnabledOnProduct(sku, true);
  const currentStoreId = useGetCurrentStoreId();
  const voucherAmount = useVoucherAmountOnStore(currentStoreId);

  return useMemo(() => {
    return !isVoucherEnabledOnProduct ? 0 : voucherAmount;
  }, [isVoucherEnabledOnProduct, voucherAmount]);
}

export function useIsVoucherEnabledOnProducts(withCurrentStore = true) {
  const getAtom = useGetAtom();
  const currentStoreId = useGetCurrentStoreId();
  const isVoucherEnabledOnStore = useIsVoucherEnabledOnStore(currentStoreId);
  const [fetchDate] = useAtom(atomFetchDate, new Date());

  return useCallback(
    async (skus) => {
      if (withCurrentStore && !isVoucherEnabledOnStore) {
        return false;
      }

      let voucherValue = await Promise.all(
        skus.map(async (sku) => {
          const [voucher] = await getAtom(atomProducts(sku));
          return isVoucherEnabled(voucher, fetchDate);
        })
      );
      if (voucherValue.includes(true)) {
        return true;
      } else {
        return false;
      }
    },
    [getAtom, isVoucherEnabledOnStore, withCurrentStore, fetchDate]
  );
}

export function useCartVoucherPrice() {
  const currentStoreId = useGetCurrentStoreId();
  const isVoucherEnabledOnStore = useIsVoucherEnabledOnStore(currentStoreId);
  const voucherAmount = useVoucherAmountOnStore(currentStoreId);
  const [fetchDate] = useAtom(atomFetchDate, new Date());
  const getAtom = useGetAtom();

  return useCallback(
    async (products) => {
      if (!isVoucherEnabledOnStore || !products || products.length === 0) {
        return 0;
      }
      let prices = await Promise.all(
        Object.keys(products).map(async (key) => {
          const product = products[key];
          const [voucher] = getAtom(atomProducts(key));
          if (isVoucherEnabled(voucher, fetchDate)) {
            return (
              ((product.priceVIP ?? product.price) - (product.ecopart ?? 0)) *
              product.count *
              voucherAmount
            );
          } else {
            return 0;
          }
        })
      );
      return prices.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    },
    [isVoucherEnabledOnStore, getAtom, fetchDate, voucherAmount]
  );
}

function VoucherBase({ children }) {
  const setAtom = useSetAtom();
  const { data } = useData("GET json-config /json/cms/vouchers.json");

  useEffect(() => {
    if (!data) {
      return;
    }
    setAtom(atomFetchDate, new Date());
    const payload = data.payload;

    if (payload && payload.Skus?.length > 0) {
      const skus = payload.Skus;

      skus.forEach((sku) => {
        // Keep only numbers in sku to avoid special characters from config
        sku.sku = sku.sku.replace(/\D/g, "");

        setAtom(atomProducts(sku.sku), {
          sku: sku.sku,
          StartAt: new Date(sku.StartAt),
          EndAt: new Date(sku.EndAt),
        });
      });
    }

    if (payload && payload.ActiveStores?.length > 0) {
      const storeConfiguration = payload.ActiveStores;
      let storesHash = [];

      storeConfiguration.forEach((activeStores) => {
        activeStores.Stores.forEach((storeId) => {
          storeId = formatStoreId(storeId);
          if (!storesHash[storeId]) {
            storesHash[storeId] = [];
          }

          storesHash[storeId].push({
            StartAt: new Date(activeStores.StartAt),
            EndAt: new Date(activeStores.EndAt),
            Amount: activeStores.Amount || 1,
          });
        });
      });

      for (const key in storesHash) {
        setAtom(atomStores(key), storesHash[key]);
      }
    }
  }, [data, setAtom]);

  return children;
}

VoucherBase.propTypes = {
  children: PropTypes.node,
};

VoucherBase.defaultProps = {
  children: null,
};

export default memo(VoucherBase);
