import React, { useMemo } from "react";

import { createGlobalStyle } from "styled-components";

import { useSkin } from "../features/drive/cmsSlice";

const SkinUI = createGlobalStyle`
  .event-skin {
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    width: 100%;
    height: 1600px;
    max-height: 100%;
  }

  .without_header {
    & .event-skin {
      top: 0
    }
  }

  body[data-skin] {
    & .page-container__outer {
      & .event-skin {
        @media screen and (min-width: 1440px) {
          display: block;
          &::before,
          &::after {
            position: absolute;
            top: 200px;
            right: 0;
            width: calc((100% - 1300px) / 2);
            height: 800px;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
          }
          &::before {
            left: 0;
          }
        }
      }
    }
    & [data-location="/cart"],
    & [data-location="/cart/"],
    & [data-location*="/order"],
    & [data-location="/order/"],
    & [data-location="/faq"],
    & [data-location="/conditions-vip"],
    & [data-location="/cgv"],
    & [data-location="/cgu"],
    & [data-location="/mentions-legales"],
    & [data-location="/paiement-echoue"],
    & [data-location*="/univers/"] {
      & .event-skin {
        display: none;
        &::before,
        &::after {
          content: none;
        }
      }
    }
  }

  body[data-skin*='${(props) => (props.skinName ? props.skinName : "*")}'] {
  & .page-container__outer {
    & .event-skin {
      @media screen and (min-width: 1440px) {
        background: linear-gradient(
          180deg,
          ${(props) =>
            props.skinImageColor1 ? props.skinImageColor1 : "transparent"} 0%,
          ${(props) =>
            props.skinImageColor2 ? props.skinImageColor2 : "transparent"} 100%
        );

        &::before {
          background-image: ${(props) =>
            props.skinImageLeft ? `url(${props.skinImageLeft})` : "none"};
        }
        &::after {
          background-image: ${(props) =>
            props.skinImageRight ? `url(${props.skinImageRight})` : "none"};
        }
      }
    }
  }
}
`;

function Skin() {
  const skin = useSkin();
  const renderedSkin = useMemo(() => {
    return !skin
      ? null
      : skin.map((item, index) => (
          <SkinUI
            skinImageColor1={item.SkinImageColor1}
            skinImageColor2={item.SkinImageColor2}
            skinImageLeft={item.SkinImageLeft}
            skinImageRight={item.SkinImageRight}
            skinName={item.SkinName}
            key={index}
          />
        ));
  }, [skin]);
  return renderedSkin;
}

export default Skin;
