import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

import clsx from "clsx";
import styled from "styled-components";

const LEFT = 0;
const LEFT_BAR_WRAPPER_WIDTH = 50;

const SlideOverContext = React.createContext();

const WrapperRootUI = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: ${LEFT}px;
  display: flex;
  width: 100%;
  background: #fefefe;
  transition: ${(props) => props.theme.transitions.create("left")};
  ${({ $closed }) => $closed && "left: 150%;"}
  ${({ $open }) => $open && `left: calc(100% - ${LEFT_BAR_WRAPPER_WIDTH}px);`}
`;
WrapperRootUI.displayName = "WrapperRootUI";

const WrapperLeftBarUI = styled.div`
  top: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  width: ${LEFT_BAR_WRAPPER_WIDTH}px;
  flex-direction: column;
  background: #632b92;
  color: #fff;
  cursor: pointer;
`;
WrapperLeftBarUI.displayName = "WrapperLeftBarUI";

const ButtonUI = styled.div`
  display: flex;
  width: ${LEFT_BAR_WRAPPER_WIDTH}px;
  height: ${LEFT_BAR_WRAPPER_WIDTH}px;
  justify-content: center;
`;
ButtonUI.displayName = "ButtonUI";

const WrapperTitleUI = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: ${LEFT_BAR_WRAPPER_WIDTH}px;
  flex-grow: 2;
  margin: 0px auto 20px auto;
  font-weight: bold;
  user-select: none;
`;
WrapperTitleUI.displayName = "WrapperTitleUI";

const ContentUI = styled.div`
  position: absolute;
  width: calc(100% - ${LEFT_BAR_WRAPPER_WIDTH}px);
  height: 100%;
  margin-left: ${LEFT_BAR_WRAPPER_WIDTH}px;
`;
ContentUI.displayName = "ContentUI";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: LEFT,
    display: "flex",
    width: `calc(100% - ${LEFT}px)`,
    background: theme.palette.primary.light,
    transition: theme.transitions.create("left"),
  },

  closed: {
    left: "150%",
  },

  folded: {
    left: `calc(100% - ${LEFT_BAR_WRAPPER_WIDTH}px)`,
  },

  title: {
    transform: "rotate(90deg)",
    transformOrigin: `${LEFT_BAR_WRAPPER_WIDTH / 5}px ${
      LEFT_BAR_WRAPPER_WIDTH / 2
    }px`,
    whiteSpace: "nowrap",
  },
}));

function DefaultTitleComponent({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

const SlideOverFoldedContext = React.createContext();
function SlideOverFoldedProvider({ children }) {
  const [folded] = useState({});
  const [setFoldedImpls] = useState({});
  const [context, setContext] = useState(() => {
    const context = {
      get: (id) => {
        return folded[id];
      },
      set: ({ id, isFolded }) => {
        folded[id] = isFolded;
        setContext({ ...context });
      },
      getFolded: (id) => folded[id],
      setFolded: (id) => {
        const setFoldedImpl = setFoldedImpls[id];
        if (setFoldedImpl) {
          return setFoldedImpl;
        }
        return (setFoldedImpls[id] = (isFolded) =>
          context.set({ id, isFolded }));
      },
    };
    return context;
  });
  return (
    <SlideOverFoldedContext.Provider value={context}>
      {children}
    </SlideOverFoldedContext.Provider>
  );
}

function useGetFolded() {
  return useContext(SlideOverFoldedContext).getFolded;
}

function useSetFolded() {
  return useContext(SlideOverFoldedContext).setFolded;
}

function SlideOverProvider({ children }) {
  const [, setN] = useState();
  const setFolded = useSetFolded();
  const value = useMemo(() => {
    let zIndex = 90000;
    const elems = {};
    return {
      elems,
      pushElem: ({ id, elem, open }) => {
        setN(Math.random());
        elems[id] = { zIndex: zIndex++, elem };
      },
      getById: (id) => elems[id],
      onClose: (id) => {
        delete elems[id];
        setN(Math.random());
      },
      raise: (id) => {
        elems[id].zIndex = zIndex++;
        setFolded(id)[1](false);
        setN(Math.random());
      },
    };
  }, [setFolded]);

  return (
    <SlideOverContext.Provider value={value}>
      {children}
      {Object.entries(value.elems).map(([id, { zIndex, elem }]) => (
        <div style={{ zIndex, position: "fixed" }} key={id}>
          {elem}
        </div>
      ))}
    </SlideOverContext.Provider>
  );
}

function SlideOverElem({
  className,
  title,
  children,
  TitleComponent,
  open,
  onClose,
  id,
}) {
  const folded = useGetFolded()(id);
  const setFolded = useSetFolded()(id);

  const classes = useStyles();

  const handleFold = useCallback(() => setFolded(true), [setFolded]);
  const handleUnfold = useCallback(() => setFolded(false), [setFolded]);

  const Title = TitleComponent || DefaultTitleComponent;

  return (
    <Paper
      elevation={24}
      className={clsx(
        className,
        classes.root,
        !open && classes.closed,
        open && folded && classes.folded
      )}
    >
      <WrapperLeftBarUI className="SlideOver-bar">
        <WrapperTitleUI onClick={folded ? handleUnfold : handleFold}>
          <Title className={classes.title}>{title}</Title>
        </WrapperTitleUI>
        {folded ? (
          <ButtonUI onClick={handleUnfold}>
            <ChevronLeftIcon className={classes.icon} />
          </ButtonUI>
        ) : (
          <ButtonUI onClick={handleFold}>
            <ChevronRightIcon className={classes.icon} />
          </ButtonUI>
        )}
        <ButtonUI onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </ButtonUI>
      </WrapperLeftBarUI>
      <ContentUI>{open && children}</ContentUI>
    </Paper>
  );
}

function SlideOverElemDelay({ openDelay, ...props }) {
  const [open, setOpen] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setOpen(openDelay);
    }, 100);
  }, [openDelay]);
  return <SlideOverElem {...props} open={open} />;
}

export default function SlideOver(props) {
  const { open, id, onClose } = props;
  const slideOverContext = useContext(SlideOverContext);

  useEffect(() => {
    if (open) {
      const elem = slideOverContext.getById(id);
      if (elem) {
        slideOverContext.raise(id);
      } else {
        const handleOnClose = () => {
          slideOverContext.onClose(id);
          if (onClose) {
            onClose();
          }
        };
        const elem = (
          <SlideOverElemDelay
            {...props}
            onClose={handleOnClose}
            openDelay={open}
          />
        );
        slideOverContext.pushElem({ id, elem, open });
      }
    }
  }, [id, open, slideOverContext, onClose, props]);
  return null;
}

export function SlideOverComboProvider({ children }) {
  return (
    <SlideOverFoldedProvider>
      <SlideOverProvider>{children}</SlideOverProvider>
    </SlideOverFoldedProvider>
  );
}
