import { combineReducers } from "@reduxjs/toolkit";

import cmsReducer from "./cmsSlice";
import navReducer from "./navSlice";
import offerReducer from "./offerSlice";
import orderReducer from "./orderSlice";
import productsReducer from "./productSlice";

export default combineReducers({
  products: productsReducer,
  orders: orderReducer,
  nav: navReducer,
  offers: offerReducer,
  cms: cmsReducer,
});
