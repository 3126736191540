import { memo, useMemo, useCallback, useEffect } from "react";

import {
  propagateCartClearedEvent,
  useUserContext,
} from "@mobsuccess-devops/gifi-api-abstraction";
import { useAtom, useSetAtom, useGetAtom } from "@mobsuccess-devops/react-atom";

import axios from "axios";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { getApiUrls } from "../../../../features/drive/constants";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../../features/drive/localStorage";
import { selectGiftCardContents } from "../../../../features/drive/offerSlice";
import { useAllProducts } from "../../../../features/drive/product";
import { useGetCurrentStoreId } from "../Store";

const atomCartItems = "#Drive.CartBase.cartItems";
const atomCartPaymentError = "#Drive.CartBase.cartPaymentError";

export function useAllCartItems() {
  const [cartItems] = useAtom(atomCartItems, initAtomItem);
  return cartItems;
}

export function useIncreaseSkuInCart() {
  const getAtom = useGetAtom();
  return useCallback(
    async (sku) => {
      const [cartItems, setCartItems] = await getAtom(atomCartItems);
      const newCartItems = {
        ...cartItems,
        [sku]: (cartItems[sku] || 0) + 1,
      };

      setCartItems(newCartItems);
      setLocalStorage("cartChanged", new Date().getTime());
    },
    [getAtom]
  );
}

export function useDecreaseSkuInCart() {
  const getAtom = useGetAtom();
  return useCallback(
    async (sku) => {
      const [cartItems, setCartItems] = await getAtom(atomCartItems);
      const newCartItems = {
        ...cartItems,
        [sku]: Math.max(0, (cartItems[sku] || 0) - 1),
      };

      if (newCartItems[sku] === 0) {
        delete newCartItems[sku];
      }
      setCartItems(newCartItems);

      if (Object.keys(newCartItems).length === 0) {
        propagateCartClearedEvent();
      }

      setLocalStorage("cartChanged", new Date().getTime());
    },
    [getAtom]
  );
}

export function useRemoveSkusFromCart() {
  const getAtom = useGetAtom();
  return useCallback(
    async (skus) => {
      const [cartItems, setCartItems] = await getAtom(atomCartItems);
      const newCartItems = { ...cartItems };
      let hasChanged = false;
      for (const sku of skus) {
        if (newCartItems[sku]) {
          delete newCartItems[sku];
          hasChanged = true;
        }
      }
      if (hasChanged) {
        setCartItems(newCartItems);
      }
    },
    [getAtom]
  );
}

export function useSetSkusInCart() {
  const getAtom = useGetAtom();
  return useCallback(
    async (items) => {
      const [cartItems, setCartItems] = await getAtom(atomCartItems);
      const newCartItems = { ...cartItems };
      items.forEach((item) => {
        newCartItems[item.sku] = Math.max(cartItems[item.sku] || 0, item.count);
      });
      setCartItems(newCartItems);
    },
    [getAtom]
  );
}

export function useCartCount(filterOnAvailability = true) {
  const items = useAllCartItems();
  const products = useAllProducts();

  return useMemo(() => {
    const filteredItems = filterOnAvailability
      ? Object.entries(items).filter(([sku]) => {
          const product = products.find((x) => x.sku === sku);
          return product?.isAvailableForPurchase;
        })
      : Object.entries(items);
    return filteredItems.reduce((s, [, count]) => s + count, 0);
  }, [items, products, filterOnAvailability]);
}

export function useCartPrice() {
  const items = useAllCartItems();
  const products = useAllProducts();

  return useMemo(() => {
    return Object.entries(items)
      .filter(([sku]) => {
        const product = products.find((x) => x.sku === sku);
        return product?.isAvailableForPurchase;
      })
      .map(([sku, count]) => {
        const product = products.find((x) => x.sku === sku);
        return product?.price * count;
      })
      .reduce((s, x) => s + x, 0);
  }, [items, products]);
}

export function useCartPriceVIP() {
  const items = useAllCartItems();
  const products = useAllProducts();

  return useMemo(() => {
    return Object.entries(items)
      .filter(([sku]) => {
        const product = products.find((x) => x.sku === sku);
        return product?.isAvailableForPurchase;
      })
      .map(([sku, count]) => {
        const product = products.find((x) => x.sku === sku) || {
          price: NaN,
          priceVIP: NaN,
        };

        return (product.priceVIP || product.price) * count;
      })
      .reduce((s, x) => s + x, 0);
  }, [items, products]);
}

export function useSkuCount(sku) {
  const items = useAllCartItems();
  return useMemo(() => {
    return items[sku] || 0;
  }, [items, sku]);
}

export function useCartContents() {
  const items = useAllCartItems();
  const products = useAllProducts();

  return useMemo(() => {
    return Object.fromEntries(
      Object.entries(items).map(([sku, count]) => {
        const product = products.find((x) => x.sku === sku);
        return [
          sku,
          {
            price: product?.price,
            priceVIP: product?.priceVIP,
            pricePreDiscount: product?.pricePreDiscount,
            ecopart: product?.ecopart,
            count,
          },
        ];
      })
    );
  }, [items, products]);
}

export function useGiftCartContents() {
  const storeId = useGetCurrentStoreId();
  const cartContents = useCartContents();
  const { user } = useUserContext();
  const isVip = useMemo(() => user?.isVip || false, [user]);

  const selectGiftCardContentsMemo = useMemo(
    () => createSelector(selectGiftCardContents, (contents) => contents),
    []
  );

  return useSelector(
    useCallback(
      (state) =>
        selectGiftCardContentsMemo(state, cartContents, storeId, isVip),
      [cartContents, selectGiftCardContentsMemo, storeId, isVip]
    )
  );
}

export function useResetCart() {
  const getAtom = useGetAtom();

  return useCallback(async () => {
    const [cartItems, setCartItems] = await getAtom(atomCartItems);
    if (Object.keys(cartItems).length > 0) {
      setCartItems({});
    }

    const [paymentError, setPaymentError] = await getAtom(atomCartPaymentError);
    if (paymentError !== null) {
      setPaymentError(null);
    }
  }, [getAtom]);
}

export function usePay() {
  const cartItems = useAllCartItems();
  const cartContents = useCartContents();
  const cartPrice = useCartPrice();

  const storeId = useGetCurrentStoreId();
  const setPaymentError = useSetPaymentError();
  return useCallback(
    async ({
      storeName,
      firstname,
      lastname,
      email,
      phone,
      address1,
      postcode,
      city,
      country,
      consentEmail,
      consentSMS,
      consentVIP,
      prepaymentAmount,
      accessToken,
      userId,
    }) => {
      let apiResponse;

      const makeRequest = async () => {
        return await axios({
          timeout: 30e3,
          method: "get",
          url: getApiUrls().PlaceOrder,
          params: {
            storeId,
            storeName,
            firstname,
            lastname,
            email,
            phone: phone.replace(/ /g, ""),
            cart: JSON.stringify(cartItems),
            cartContents: JSON.stringify(cartContents),
            cartPrice,
            address1,
            postcode,
            city,
            country,
            consentEmail: consentEmail ? 1 : 0,
            consentSMS: consentSMS ? 1 : 0,
            consentVIP: consentVIP ? 1 : 0,
            frontendVersion: 1, // increment this to debug the PlaceOrder bug and show different revisions in the logs
            ...(!prepaymentAmount ? { noPrepaymentAmount: true } : {}),
            shopTerminal: getLocalStorage("disablePrepayment"),
          },
          headers: {
            "X-Reachfive-Token": accessToken,
            "X-Gifi-Identifiant": userId,
          },
        });
      };

      try {
        setPaymentError(null);
        apiResponse = await makeRequest();
      } catch (e) {
        if (e.code === "ECONNABORTED") {
          // we got a timeout, try again one more time
          try {
            apiResponse = await makeRequest();
          } catch (e) {
            // this time, even if we timeout, we bail out
            return setPaymentError(e.toString());
          }
        } else {
          return setPaymentError(e.toString());
        }
      }

      return {
        paymentUrl: apiResponse.data.redirect,
        orderId: apiResponse.data.orderId,
      };
    },
    [storeId, cartItems, cartContents, cartPrice, setPaymentError]
  );
}

export function usePaymentError() {
  const [paymentError] = useAtom(atomCartPaymentError, null);
  return paymentError;
}

function useSetPaymentError() {
  const setAtom = useSetAtom();
  return useCallback(
    (error) => {
      setAtom(atomCartPaymentError, error);
    },
    [setAtom]
  );
}

function initAtomItem() {
  const cartItems = getLocalStorage("cartItems") || "{}";

  const cartChanged = getLocalStorage("cartChanged");
  if (cartChanged) {
    const expiredDate =
      new Date(Number(cartChanged)).getTime() + 24 * 60 * 60 * 1000;
    if (new Date() > new Date(expiredDate)) {
      return {};
    }
    return JSON.parse(cartItems);
  }
  return JSON.parse(cartItems);
}

function CartBase({ children }) {
  const [cartItems] = useAtom(atomCartItems, initAtomItem);
  useEffect(() => {
    setLocalStorage("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);
  return children;
}

CartBase.propTypes = {
  children: PropTypes.node,
};

CartBase.defaultProps = {
  children: null,
};

export default memo(CartBase);
