import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";

import styled, {
  ThemeProvider as StyledThemeProvider,
} from "styled-components";

import { theme as defaultTheme } from "../msTheme";
import styledTheme from "../theme/theme";

const WrapperRootUI = styled.div`
  &,
  button,
  select,
  input {
    font-family: Montserrat, Helvetica, sans-serif;
  }
`;
WrapperRootUI.displayName = "WrapperRootUI";

export default function MsThemeProvider({ theme, children }) {
  if (!theme) {
    theme = defaultTheme;
  }
  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={styledTheme}>
        <WrapperRootUI>{children}</WrapperRootUI>
      </StyledThemeProvider>
    </ThemeProvider>
  );
}
