import React, { useState } from "react";

import { createPortal } from "react-dom";
import styled from "styled-components";

import MsApiLoadingIndicator from "./MsApiLoadingIndicator";

const WrapperUI = styled(MsApiLoadingIndicator)`
  position: fixed;
  z-index: 9999999;
`;
WrapperUI.displayName = "WrapperUI";

export default function MsApiLoadingIndicatorPortal() {
  const [container] = useState(
    document.getElementById("react_loading_indicator")
  );
  if (container) {
    return createPortal(<MsApiLoadingIndicator />, container);
  } else {
    return <WrapperUI />;
  }
}
