import { useEffect } from "react";

import { captureException } from "@sentry/react";

import axios from "axios";
import PropTypes from "prop-types";

function AxiosSentryInterceptBase({ children }) {
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        captureException(error, {
          extra: error?.config,
          tags: {
            interceptor: "AxiosInterceptor",
            ...(error?.response?.status && {
              httpStatusCode: error?.response?.status,
            }),
          },
        });
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  return children;
}

AxiosSentryInterceptBase.propTypes = {
  children: PropTypes.node,
};

AxiosSentryInterceptBase.defaultProps = {
  children: null,
};

export default AxiosSentryInterceptBase;
