import {
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { useLocation, useHistory } from "react-router-dom";

import { ParamContext } from "./ParamProvider";
import log from "./log";
import { getFinalURL, getUrlObject } from "./utils";
import { parseValue, encodeValues } from "./values";

/**
 * Returns a function that can further be curried with an URL and will return
 * another URL keeping all the parameters defined in the `ParamContext`.
 *
 * @method useGetUrl
 * @return {Function} a function: `(to, params) => String`, returning an URL for which
 * the added params have been added
 */
export function useGetUrl() {
  const { keep, locationSearchRef, paramsRef } = useContext(ParamContext);
  const location = useLocation();
  const locationRef = useRef();
  useMemo(() => {
    locationRef.current = location;
  }, [location]);

  /*
  const search = `?${new URLSearchParams(
    `${location.search}&${paramsRef.current.toString()}`
  ).toString()}`.replace(/^\?$/, "");
  */
  const paramsRefString = paramsRef.current.toString();
  return useCallback(
    (to, params) => {
      const { pathname } = locationRef.current;
      const sp = new URLSearchParams(locationSearchRef.current);
      Array.from(new URLSearchParams(paramsRefString).entries()).forEach(
        ([param, value]) => {
          // maybe the next line is not needed BUT we had so many bugs today that
          // I'm not confident removing it
          /* istanbul ignore next */
          if (!sp.has(param)) {
            sp.set(param, value);
          }
        }
      );
      const search = `?${sp.toString()}`;
      return getFinalURL({ location: { pathname }, keep, to, params, search });
    },
    [keep, locationSearchRef, paramsRefString]
  );
}

export function useGetUrlObject() {
  const { keep } = useContext(ParamContext);
  const location = useLocation();
  return useCallback(
    (to, params) => {
      return getUrlObject({ location, keep, to, params });
    },
    [keep, location]
  );
}

function useWakeAll() {
  const context = useContext(ParamContext);
  const { wakersRef } = context;
  const [, setWaker] = useState(0);
  useEffect(() => {
    const waker = () => setWaker(Math.random());
    context.wakersRef.current.push(waker);
    return () => {
      context.wakersRef.current = context.wakersRef.current.filter(
        (x) => x !== waker
      );
    };
  }, [context.wakersRef]);
  return useCallback(() => {
    wakersRef.current.forEach((waker) => waker());
  }, [wakersRef]);
}

/**
 * Returns a function that allows to push an URL to the history.
 *
 * @method useHistoryPush
 * @method {String} url the URl to push to
 */
export function useHistoryPush() {
  const getUrl = useGetUrl();
  const history = useHistory();
  const context = useContext(ParamContext);
  const locationRef = useRef();
  const location = useLocation();
  useMemo(() => {
    locationRef.current = location;
  }, [location]);
  return useCallback(
    (to, params = {}) => {
      const newTo = getUrl(to, params);
      const current = `${locationRef.current.pathname}${context.locationSearchRef.current}`;
      if (current !== newTo) {
        log(`historyPush(${newTo}) begin`);
        history.push(newTo);
        context.paramsRef.current = new URL(
          newTo,
          "https://example.com"
        ).searchParams;
        context.locationSearchRef.current = new URL(
          newTo,
          "https://www.example.com"
        ).search;
        log(
          `historyPush(${newTo}) done`,
          context.paramsRef.current.toString(),
          context.locationSearchRef.current.toString()
        );
      }
    },
    [context.locationSearchRef, context.paramsRef, getUrl, history]
  );
}

/**
 * Returns a function that pushes param change and commit the location after a specified
 * amount of time has passed.
 *
 * @method usePush
 * @private
 */
function usePush() {
  const history = useHistory();
  const wakeAll = useWakeAll();
  const context = useContext(ParamContext);
  const { minimumDelay, paramsRef } = context;
  const locationRef = useRef();
  const location = useLocation();
  useMemo(() => {
    locationRef.current = location;
  }, [location]);
  const timerRef = useRef();
  return useCallback(
    function push(values) {
      const { lastPush } = context;
      let changed = false;
      Object.entries(values).forEach(([param, value]) => {
        if (value === null) {
          changed = true;
          paramsRef.current.delete(param);
        } else if (paramsRef.current.get(param) !== value) {
          changed = true;
          paramsRef.current.set(param, value);
        }
      });
      if (!changed) {
        return;
      }
      if (timerRef.current) {
        return;
      }
      const now = Date.now();
      const delaySinceLastPush = now - lastPush;
      context.lastPush = now;

      const doit = () => {
        timerRef.current = null;
        const paramsString = paramsRef.current.toString();
        const url = [
          locationRef.current.pathname,
          paramsString ? `?${paramsString}` : "",
          locationRef.current.hash || "",
        ].join("");
        log(`push(${url}) begin`);
        history.push(url);
        wakeAll();
        log(`push(${url}) done`);
      };
      if (minimumDelay < 0) {
        doit();
      } else {
        timerRef.current = setTimeout(
          doit,
          delaySinceLastPush > minimumDelay ? 0 : minimumDelay
        );
      }
      push.now = doit;
    },
    [context, history, locationRef, minimumDelay, paramsRef, wakeAll]
  );
}

/**
 * A custom hook to retrieve values and setters for search params.
 *
 * You can use it like so:
 *
 * ```
 * const searchParams = useSearchParams();
 * const [ filter, setFilter ] = searchParams.param('filter', '')
 * ````
 *
 * Multiple calls to setters are queued until the next tick, so you
 * can change more than values and not have React refresh intermediate
 * component for every intermediate changes.
 *
 * Depending on your use case, it might be best to use `withParams()`
 * to wrap a component with values and setters for your search params.
 *
 * @method useSearchParams
 * @return {Object} an instance with a method `param(name, defaultValue)`
 */
export function useSearchParams() {
  const wakeAll = useWakeAll();
  const location = useLocation();
  const context = useContext(ParamContext);
  const { cache, setters, paramsRef } = context;

  const history = useHistory();
  const push = usePush();
  const pushRef = useRef();
  useMemo(() => {
    pushRef.current = push;
  }, [push]);

  const cached = cache.filter((x) => x.location === location);
  if (cached.length) {
    return cached[0].result;
  }

  const useParams = {};
  const param = (name, defaultValue) => {
    const value = parseValue(paramsRef.current, name, defaultValue);
    if (useParams[name]) {
      useParams[name][0] = value;
      return useParams[name];
    }

    let setter = setters.find(
      ({ name: setterName, defaultValue: setterDefaultValue }) =>
        name === setterName && defaultValue === setterDefaultValue
    )?.setter;
    if (setter) {
      setter.pushRef = pushRef;
    } else {
      setter = function setterFunction(newValue) {
        if (newValue === defaultValue) {
          setterFunction.pushRef.current({ [name]: null });
        } else {
          encodeValues(paramsRef.current, name, newValue, defaultValue).forEach(
            ([encodedName, encodedValue]) =>
              setterFunction.pushRef.current({ [encodedName]: encodedValue })
          );
        }
      };
      setter.replacer = function replacerFunction(newValue) {
        if (pushRef.current.now) {
          pushRef.current.now();
        }
        const oldUri = paramsRef.current.toString();
        if (newValue === null) {
          paramsRef.current.delete(name);
        } else if (paramsRef.current.get(name) !== newValue) {
          paramsRef.current.set(name, newValue);
        }
        const newUri = paramsRef.current.toString();
        if (oldUri === newUri) {
          return;
        }

        history.replace(
          `?${paramsRef.current.toString()}${location.hash || ""}`
        );
        wakeAll();
      };
      setter.pushRef = pushRef;
      setters.push({ setter, name, defaultValue });
    }
    useParams[name] = [value, setter, setter.replacer];
    return useParams[name];
  };

  const result = {
    get: (paramName) => paramsRef.current.get(paramName),
    entries: () => paramsRef.current.entries(),
    push,
    param,
  };
  cache.push({ location, result });
  log(`useSearchParams()`, paramsRef.current.toString());
  return result;
}
