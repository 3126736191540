import { useMemo, useEffect } from "react";

import { useLocation } from "react-router-dom";

export default function Puppeteer({ location = window.location }) {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("puppeteer")) {
      localStorage.setItem("msPuppeteer", "true");
      params.delete("puppeteer");
      const paramsString = params.toString();
      window.location.search = `${paramsString ? `?${paramsString}` : ""}`;
    }
  }, [location]);
  return null;
}

export function hasPuppeteer({ location = window.location } = {}) {
  return (
    !!window.msPuppeteer ||
    new URLSearchParams(location.search).has("puppeteer") ||
    (() => {
      try {
        return !!localStorage.getItem("msPuppeteer");
      } catch (e) {}
    })()
  );
}

export function useHasPuppeteer() {
  const location = useLocation();
  return useMemo(() => hasPuppeteer({ location }), [location]);
}
