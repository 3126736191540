export function getLocalStorage(item, defaultValue = null) {
  try {
    return localStorage.getItem(item) || defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

export function setLocalStorage(item, newValue) {
  try {
    return localStorage.setItem(item, newValue);
  } catch (e) {
    window.error(e);
  }
}
