import { sentryReduxEnhancer } from "@mobsuccess-devops/react-sentry";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import msApiReducer from "../features/api/msApiSlice";
import driveReducer from "../features/drive/driveSlice";

const store = configureStore({
  reducer: {
    msApi: msApiReducer,
    drive: driveReducer,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
});
export default window.reduxStore = store;
