import { createSelector } from "reselect";

import { selectActiveContentById } from "../cmsSlice";

function selectProductTagsImpl(data) {
  const byProduct = {};
  const dynamicTags = {};
  (data || []).forEach(({ Tags = {}, DynamicTags = {} }) => {
    for (const [tag, resolver] of Object.entries(DynamicTags)) {
      /* eslint no-new-func:off */
      dynamicTags[tag] = new Function(["product"], `return ${resolver}`);
    }
    for (const [tag, skus] of Object.entries(Tags)) {
      skus.forEach((sku) => {
        if (!byProduct[sku]) {
          byProduct[sku] = [tag];
        } else if (byProduct[sku].indexOf(tag) === -1) {
          byProduct[sku].push(tag);
        }
      });
    }
  });

  return { byProduct, dynamicTags };
}

export const selectProductTags = createSelector(
  [(state) => state, (state, storeId) => storeId],
  (state, storeId) => {
    return selectProductTagsImpl(
      selectActiveContentById(state, { id: "product-tags", storeId })
    );
  }
);

export function selectApplicableTagsForProduct(state, product, storeId) {
  const { byProduct, dynamicTags } = selectProductTags(state, storeId);
  const { sku } = product;
  return [
    ...(byProduct[sku] || []),
    ...Object.entries(dynamicTags)
      .map(([tag, resolver]) => (resolver(product) ? tag : null))
      .filter(Boolean),
  ];
}
