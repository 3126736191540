import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import styled from "styled-components";

import { useRunningRequests } from "../features/api/msApiSlice";

const WrapperUI = styled(CircularProgress)`
  top: Opx;
  right: 0px;
  padding: 0.4rem;
`;
WrapperUI.displayName = "WrapperUI";

function MsApiLoadingIndicatorBase({ className = null, size = 16 }) {
  return <WrapperUI size={size} className={className} />;
}

export default function MsApiLoadingIndicator(props) {
  const runningRequests = useRunningRequests();
  return runningRequests ? <MsApiLoadingIndicatorBase {...props} /> : null;
}
