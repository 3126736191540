/* istanbul ignore file */
export default function log(message, ...args) {
  try {
    if (localStorage.getItem("react-router-dom-search-params-log")) {
      window.console.log(`rrdsp: ${message}`, ...args);
    }
  } catch (e) {
    // ignore
  }
}
