import { hasPuppeteer } from "../../components/Puppeteer";

import { dataValidityMs } from "./constants";
import { defaultStoreId } from "./constants";

const enabled = true;

const serialized = unserializeReduxFromLS();

export function clearRedux() {
  localStorage.removeItem("redux");
  localStorage.removeItem("redux_version");
  localStorage.removeItem("redux_timestamp");
}

function initEskimoz() {
  if (
    hasPuppeteer() ||
    navigator.userAgent === "ba32e3a1-8d9c-4a2d-8121-adf3caaf23d5"
  ) {
    localStorage.setItem("storeId", defaultStoreId);
  }
}

export function getReduxVersion() {
  const version = localStorage.getItem("redux_version");
  return version;
}

export function unserializeReduxFromLS(fn) {
  initEskimoz();
  try {
    // fix a bug when an unreplaced macro was given as store_id
    if (localStorage.getItem("storeId").match(/^{/)) {
      localStorage.clear();
    }
  } catch (e) {}
  try {
    const timestamp = Number(localStorage.getItem("redux_timestamp"));
    if (!timestamp || (timestamp && Date.now() - timestamp > dataValidityMs)) {
      clearRedux();
    }
    return JSON.parse(localStorage.getItem("redux")) || {};
  } catch (e) {
    return {};
  }
}

export function serializeRedux(fn) {
  if (!enabled) {
    return;
  }

  fn(serialized);
  triggerSave();
}

export function setReduxVersion(dataVersion) {
  localStorage.setItem("redux_version", dataVersion);
}

let timer = null;
export function triggerSave() {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    timer = null;
    try {
      localStorage.setItem("redux", JSON.stringify(serialized));
      if (!Number(localStorage.getItem("redux_timestamp"))) {
        localStorage.setItem("redux_timestamp", Date.now());
      }
    } catch (e) {}
  }, 500);
}

export function unserializeRedux(fn, defaultValue) {
  if (!enabled) {
    return defaultValue;
  }

  try {
    return fn(serialized) || defaultValue;
  } catch (e) {
    return defaultValue;
  }
}
