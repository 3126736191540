import React from "react";

import { DataContext } from "@mobsuccess-devops/react-data-context";
import { ParamProvider } from "@mobsuccess-devops/react-router-dom-search-params";

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "url-search-params-polyfill";

import App from "./App";
import store from "./app/store";
import Localized from "./components/Localized";
import MsApiLastErrorSnack from "./components/MsApiLastErrorSnack";
import MsApiLoadingIndicatorPortal from "./components/MsApiLoadingIndicatorPortal";
import MsThemeProvider from "./components/MsThemeProvider";
import {
  PlatformUrlBaseProvider,
  getEntryPointUrlBase,
} from "./components/PlatformUrlBase";
import { SlideOverComboProvider } from "./components/SlideOver";
import { language } from "./features/drive/constants";
import "./index.css";
import Skin from "./theme/Skin";

const lang = (() => {
  if (window._ms_locale) {
    return window._ms_locale;
  }
  const params = new URLSearchParams(window.location.search);
  if (params.get("lang")) {
    return params.get("lang");
  }
  return language;
})();

const reactRoot = (() => {
  try {
    const topReactRoot = window.top.document.getElementById("react_root");
    if (topReactRoot) {
      return topReactRoot;
    }
  } catch (e) {}
  return (
    document.getElementById("react_root") || document.getElementById("root")
  );
})();

ReactDOM.render(
  <React.StrictMode>
    <MsThemeProvider>
      <DataContext>
        <Provider store={store}>
          <BrowserRouter basename={getEntryPointUrlBase()}>
            <ParamProvider keep={["lang"]}>
              <Localized language={lang}>
                <SlideOverComboProvider>
                  <PlatformUrlBaseProvider>
                    <MsApiLastErrorSnack />
                    <Skin />
                    <App />
                    <MsApiLoadingIndicatorPortal />
                  </PlatformUrlBaseProvider>
                </SlideOverComboProvider>
              </Localized>
            </ParamProvider>
          </BrowserRouter>
        </Provider>
      </DataContext>
    </MsThemeProvider>
  </React.StrictMode>,
  reactRoot
);
