import React, { createContext, useMemo, useRef, useContext } from "react";

import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

export const ParamContext = createContext();

/**
 * Browsers do not like if history is pushed too often. If we try to push
 * another URL faster than this amount, then we will wait the required
 * amount of time so that we don't get errors in the console, and the
 * browser does not ignore our request.
 *
 * @const MINIMUM_DELAY_BETWEEN_TWO_HISTORY_PUSH_IN_MS
 * @type {Number}
 */
const MINIMUM_DELAY_BETWEEN_TWO_HISTORY_PUSH_IN_MS = 300;

const LocationSyncer = ({ children }) => {
  const location = useLocation();
  const { locationSearchRef } = useContext(ParamContext);
  useMemo(() => {
    if (locationSearchRef.current !== location.search) {
      locationSearchRef.current = location.search;
    }
  }, [location.search, locationSearchRef]);
  return children;
};

/**
 * A React component that should be added on the topmost component on which
 * you want to use this module.
 *
 * @method ParamProvider
 * @param {Array} keep an optional array of the parameters that should be
 * be kept when navigating from one page to another
 */
export default function ParamProvider({ keep, minimumDelay, children }) {
  const setters = useMemo(() => [], []);
  const cache = useMemo(() => [], []);
  const location = useLocation();
  const locationSearchRef = useRef(location.search);
  const paramsRef = useRef(new URLSearchParams(locationSearchRef.current));
  const wakersRef = useRef([]);
  const value = useMemo(
    () => ({
      keep,
      wakersRef,
      lastPush: 0,
      cache,
      setters,
      minimumDelay,
      paramsRef,
      locationSearchRef,
    }),
    [cache, setters, keep, minimumDelay, paramsRef]
  );

  return (
    <ParamContext.Provider value={value}>
      <LocationSyncer>{children}</LocationSyncer>
    </ParamContext.Provider>
  );
}
ParamProvider.displayName = "ParamProvider";

ParamProvider.propTypes = {
  keep: PropTypes.arrayOf(PropTypes.string),
  minimumDelay: PropTypes.number,
  children: PropTypes.node,
};

ParamProvider.defaultProps = {
  keep: [],
  minimumDelay: MINIMUM_DELAY_BETWEEN_TWO_HISTORY_PUSH_IN_MS,
  children: null,
};
