import { useSelector } from "react-redux";

import { selectSearch } from "./navSlice";
import sluggize from "./sluggize";

export function normalizeSearch(s) {
  return sluggize(s || "")
    .split(/-/)
    .filter((s) => s.length > 2 && s.match(/[a-z]/));
}

export function createSearch(search) {
  const terms = normalizeSearch(search);

  return (a) => {
    const n = normalizeSearch(a);
    return !terms.some((term) => n.indexOf(term) === -1);
  };
}

export function useSearch() {
  return useSelector(selectSearch);
}
