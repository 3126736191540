import { useEffect, useRef } from "react";

import { Redirect as ReactRouterDomRedirect } from "react-router-dom";

import { useHistoryPush } from "./hooks";
import log from "./log";

/**
 * This component wraps a `<Link>` and lets you easily assign search params
 * in addition to a `to` URL.
 *
 * @method ParamLink
 * @param {String} to the pathname of the new URL, defaults to the current `pathname`
 */
export default function Redirect({ to }) {
  const historyPush = useHistoryPush();
  const toRef = useRef(to);
  useEffect(() => {
    if (toRef.current) {
      log(`<Redirect> to current = ${toRef.current}`);
      historyPush(to);
      log(`<Redirect> set to current = null`);
      toRef.current = null;
    }
  }, [historyPush, to]);
  return null;
}
Redirect.propTypes = ReactRouterDomRedirect.propTypes;
