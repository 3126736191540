import React from "react";

import {
  initSentry,
  ConsentManagerTransport,
} from "@mobsuccess-devops/react-sentry";

import "./App.css";
import AxiosSentryIntercept from "./components/AxiosSentryIntercept";
import Entry from "./entry-points/Entry";
import { environment } from "./features/drive/constants";

initSentry({
  dsn: "https://b4ba4e50fbed4b60a241132ecf9b8816@o1028474.ingest.sentry.io/6066270",
  environment,
  transport: ConsentManagerTransport,
});

function App() {
  return (
    <div className="App">
      <AxiosSentryIntercept>
        <Entry />
      </AxiosSentryIntercept>
    </div>
  );
}

export default App;
