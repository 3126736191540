import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { current } from "immer";

import sleep from "../../sleep";

import { axiosGetOne, axiosGetOneCache } from "./api";
//import { sluggizeArray } from "./sluggize";
import { selectApplicableTagsForProduct } from "./cms/productTags";
import { selectCategories } from "./cmsSlice";
import {
  formatStoreId,
  messageCodeExcluded,
  messageCodeAvailable,
} from "./constants";
import { getThresholdStock, getProductMessageCode } from "./product";
import { serializeRedux, unserializeRedux } from "./reduxSerialize";

const productAdapter = createEntityAdapter({
  selectId: (x) => x.sku,
});
const categoriesAdapter = createEntityAdapter();

function serializeProducts(state) {
  if (!state.pending && !state.pendingLoadingAllProducts) {
    serializeRedux(
      (serialized) =>
        (serialized.drive = { ...serialized.drive, products: current(state) })
    );
  }
}

export const fetchProductsFromCategory = createAsyncThunk(
  "drive/fetchProductsFromCategory",
  async ({ category, storeId } = {}, { getState }) => {
    const state = getState();
    return {
      category,
      data: (
        await axiosGetOneCache(
          `/json/store/${formatStoreId(storeId)}-${
            category === "*" ? "hp" : category
          }.json`
        )
      ).data.map((product, i) => ({ ...product, defaultOrder: i })),
      mergeProductTags: (product) => ({
        ...product,
        sku: String(product.sku),
        tags: selectApplicableTagsForProduct(
          state,
          product,
          formatStoreId(storeId)
        ),
      }),
    };
  }
);

export const fetchProductsFromIndex = createAsyncThunk(
  "drive/fetchProductsFromIndex",
  async ({ index, storeId } = {}, { getState, dispatch }) => {
    const state = getState();
    return {
      products: (await axiosGetOneCache(`/json/index/${index}.json`)).data.map(
        (product) => ({
          ...product,
          custom:
            product.custom || state.drive.products.custom[product.sku] || [],
        })
      ),
      mergeProductTags: (product) => ({
        ...product,
        sku: String(product.sku),
        tags: selectApplicableTagsForProduct(state, product, storeId),
      }),
    };
  }
);

export const fetchProductById = createAsyncThunk(
  "drive/fetchProductById",
  async ({ sku, storeId } = {}, { getState, dispatch }) => {
    const data = {
      sku,
      ...(await axiosGetOneCache(`/json/product/${sku}.json`)).data,
    };
    const fullState = getState();
    const state = fullState.drive.products;
    const price = (state.price ? state.price[sku] : undefined) || undefined;
    if (price) {
      data.price = price;
    }

    const pricePreDiscount =
      (state.pricePreDiscount ? state.pricePreDiscount[sku] : undefined) ||
      undefined;
    if (pricePreDiscount) {
      data.pricePreDiscount = pricePreDiscount;
    }

    const priceVIP =
      (state.priceVIP ? state.priceVIP[sku] : undefined) || undefined;
    if (priceVIP) {
      data.priceVIP = priceVIP;
    }

    const custom = (state.custom ? state.custom[sku] : undefined) || [];
    if (custom) {
      data.custom = custom;
    }

    const ecopart =
      (state.ecopart ? state.ecopart[sku] : undefined) || undefined;
    if (ecopart) {
      data.ecopart = ecopart;
    }

    const inStockBase = state.stock ? state.stock[sku] : undefined;

    const inStockDiff = (state.diff ? state.diff[sku] : 0) || 0;
    if (inStockBase !== undefined) {
      data.inStockBase = inStockBase;
      data.inStock = Math.max(0, inStockBase - inStockDiff);
    } else {
      // If is not in stock base : has been excluded or removed from store
      // add message_code 000 for code consistency
      data.custom.push(`message_code:${messageCodeExcluded}`);
    }

    return {
      product: data,
      mergeProductTags: (product) => ({
        ...product,
        sku: String(product.sku),
        tags: selectApplicableTagsForProduct(fullState, product, storeId),
      }),
    };
  }
);

const pendingFetchAllProductsFromAllCategoriesByStore = {};

export const fetchAllProductsFromAllCategories = createAsyncThunk(
  "drive/fetchAllProductsFromAllCategories",
  async ({ storeId } = {}, { getState, dispatch }) => {
    if (pendingFetchAllProductsFromAllCategoriesByStore[storeId]) {
      return await pendingFetchAllProductsFromAllCategoriesByStore[storeId];
    }
    pendingFetchAllProductsFromAllCategoriesByStore[storeId] = async () => {
      dispatch(increasePendingLoadingAllProducts());
      const state = getState();
      if (state.fetchedAllProductsFromAllCategories) {
        return;
      }
      const categories = selectAllCategories(state);
      setTimeout(async () => {
        for (const i in categories) {
          const { id } = categories[i];
          await dispatch(fetchProductsFromCategory({ category: id, storeId }));
          await sleep(50);
        }
        dispatch(decreasePendingLoadingAllProducts());
      });
    };
    try {
      return await pendingFetchAllProductsFromAllCategoriesByStore[storeId]();
    } finally {
      pendingFetchAllProductsFromAllCategoriesByStore[storeId] = null;
    }
  }
);

export const fetchBase = createAsyncThunk(
  "drive/fetchBase",
  async ({ storeId, useLive = false } = {}, { getState }) => {
    const state = getState();
    if (!storeId) {
      return { categories: [], products: [], base: { stock: {}, diff: {} } };
    }

    const products = (
      await axiosGetOneCache(`/json/store/${formatStoreId(storeId)}-hp.json`)
    ).data;

    const base = JSON.parse(
      JSON.stringify(
        (
          await axiosGetOneCache(
            useLive
              ? `/json/store-base/live/${formatStoreId(storeId)}-base.json`
              : `/json/store/${formatStoreId(storeId)}-base.json`
          )
        ).data
      )
    );

    const thresholdStockApplied = {};

    Object.entries(base.stock).forEach(([sku, stock], i) => {
      const currentPrice = base.price[sku];
      thresholdStockApplied[sku] = stock - getThresholdStock(currentPrice);
    });
    base.stock = thresholdStockApplied;

    const diff = (
      await axiosGetOne(`/json/store-diff/dist/${formatStoreId(storeId)}.json`)
    ).data;
    return {
      base,
      diff,
      categories: selectCategories(state, formatStoreId(storeId)),
      products: products,
      mergeProductTags: (product) => ({
        ...product,
        sku: String(product.sku),
        tags: selectApplicableTagsForProduct(
          state,
          product,
          formatStoreId(storeId)
        ),
      }),
    };
  }
);

const liveStockCache = {};
export const resyncStock = createAsyncThunk(
  "drive/resyncStock",
  async ({ storeId } = {}, { dispatch }) => {
    await dispatch(fetchBase({ storeId, useLive: true }));
    if (!storeId) {
      return {};
    }
    if (liveStockCache[storeId]) {
      return null;
    }
    const result = (
      await axiosGetOne(`/json/store-diff/live/${formatStoreId(storeId)}.json`)
    ).data;
    liveStockCache[storeId] = result;
    setTimeout(() => (liveStockCache[storeId] = null), 300e3);
    return result;
  }
);

function mergeStockAndProducts({
  products,
  stock,
  diff,
  ecopart,
  price,
  pricePreDiscount,
  priceVIP,
  custom,
}) {
  return products.map((product) => {
    const { sku } = product;
    const inStockBase = stock[sku] || 0;
    const inStockDiff = diff[sku] || 0;
    const itemPrice = price?.[sku] || 0;
    const itemCustom = custom[sku] || [];
    const isAvailableForPurchase = isProductAvailableForPurchase({
      productCustoms: itemCustom,
      price: itemPrice,
      inStock: inStockBase,
    });

    const inStock = Math.max(0, inStockBase - inStockDiff);
    return {
      ...product,
      inStockBase,
      inStock,
      isAvailableForPurchase,
      ecopart: ecopart[sku] || undefined,
      pricePreDiscount: pricePreDiscount[sku] || undefined,
      ...(price ? { price: price[sku] || undefined } : {}),
      priceVIP: priceVIP[sku] || undefined,
      custom: itemCustom,
    };
  });
}

const initialState = {
  pending: 0,
  products: categoriesAdapter.getInitialState(),
  categories: productAdapter.getInitialState(),
  loadedCategories: {},
  loadedProducts: {},
  stock: {},
  stockedCategories: null,
  diff: {},
  pendingLoadingAllProducts: 0,
};

export const slice = createSlice({
  name: "accounts",
  initialState: unserializeRedux((state) => state.drive.products, initialState),
  reducers: {
    loaded: (state, data) => data.payload,
    resetProducts: (state) => {
      productAdapter.removeAll(state.products);
      state.loadedCategories = {};
      state.fetchedAllProductsFromAllCategories = false;
    },
    increasePendingLoadingAllProducts: (state) => {
      state.pendingLoadingAllProducts++;
    },
    decreasePendingLoadingAllProducts: (state) => {
      state.pendingLoadingAllProducts--;
    },
  },
  extraReducers: {
    [fetchBase.pending]: (state, action) => {
      state.pending++;
    },
    [fetchBase.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        const {
          /*revision,*/
          base: {
            stock,
            price,
            price_pre_discount: pricePreDiscountRaw,
            price_vip: priceVIPRaw,
            custom: customRaw,
            ecopart: ecopartRaw,
            stocked_categories: stockedCategoriesRaw,
          },
          diff: diffDist,
          products,
          mergeProductTags,
        } = data;
        const pricePreDiscount = pricePreDiscountRaw || {};
        const priceVIP = priceVIPRaw || {};
        const custom = customRaw || {};
        const ecopart = ecopartRaw || {};
        const stockedCategories = stockedCategoriesRaw || [];
        const diff = state.diffLive || diffDist;
        state.stockedCategories = stockedCategories.length
          ? stockedCategories
          : null;
        state.price = price;
        state.pricePreDiscount = pricePreDiscount;
        state.priceVIP = priceVIP;
        state.custom = custom;
        state.ecopart = ecopart;
        state.stock = stock;
        state.diff = diff; // if we have a live diff, use it
        state.loadedProducts = {}; // we need to reload product on store change

        productAdapter.upsertMany(
          state.products,
          mergeStockAndProducts({
            products,
            price,
            stock,
            diff,
            ecopart,
            pricePreDiscount,
            priceVIP,
            custom,
          })
          //FIXME should not be needed, as it is done below
        );
        for (const sku of Object.keys(state.products.entities)) {
          state.products.entities[sku].inStockBase =
            state.products.entities[sku].inStockDiff =
            state.products.entities[sku].inStock =
              0;
        }
        Object.keys(stock).forEach((sku) => {
          const product = state.products.entities[sku];
          if (product) {
            const inStockBase = stock[sku] || 0;
            const inStockDiff = diff[sku] || 0;
            const inStock = Math.max(0, inStockBase - inStockDiff);
            const itemPrice = price[sku] || null;
            const itemPricePreDiscount = pricePreDiscount[sku] || undefined;
            const itemPriceVIP = priceVIP[sku] || undefined;
            const itemCustom = custom[sku] || [];
            const itemEcopart = ecopart[sku] || undefined;
            const isAvailableForPurchase = isProductAvailableForPurchase({
              productCustoms: itemCustom,
              price: itemPrice,
              inStock: inStock,
            });

            productAdapter.upsertOne(
              state.products,
              mergeProductTags({
                sku,
                inStockBase,
                inStock,
                isAvailableForPurchase,
                price: itemPrice,
                pricePreDiscount: itemPricePreDiscount,
                priceVIP: itemPriceVIP,
                custom: itemCustom,
                ecopart: itemEcopart,
              })
            );
          }
        });
        categoriesAdapter.removeAll(state.categories);
        categoriesAdapter.upsertMany(state.categories, data.categories);
        serializeProducts(state);
      }
    },
    [fetchBase.rejected]: (state, action) => {
      state.pending--;
      state.products = initialState.products;
      // CA 2021-04-14 this can not work and throws: categoriesAdapter.upsertMany(state.categories, selectCategories(state));
      state.stock = initialState.stock;
      state.stockedCategories = initialState.stockedCategories;
      state.diff = initialState.diff;
    },

    [fetchProductsFromCategory.pending]: (state, action) => {
      state.pending++;
    },
    [fetchProductsFromCategory.fulfilled]: (
      state,
      { payload: { data, category, mergeProductTags } }
    ) => {
      state.pending--;
      if (data) {
        state.loadedCategories[category] = true;
        const {
          stock = {},
          diff = {},
          ecopart = {},
          pricePreDiscount = {},
          priceVIP = {},
          custom = {},
          price = {},
        } = state;
        const products = data;
        productAdapter.upsertMany(
          state.products,
          mergeStockAndProducts({
            products,
            price,
            stock,
            diff,
            ecopart,
            pricePreDiscount,
            priceVIP,
            custom,
          }).map((product) => mergeProductTags(product))
        );
        serializeProducts(state);
      }
    },

    [fetchProductsFromCategory.rejected]: (
      state,
      {
        meta: {
          arg: { category },
        },
      }
    ) => {
      state.pending--;
      state.loadedCategories[category] = true;
    },
    [fetchProductsFromIndex.pending]: (state, action) => {
      state.pending++;
    },
    [fetchProductsFromIndex.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        const { products, mergeProductTags } = data;
        const {
          price = {},
          stock = {},
          diff = {},
          ecopart = {},
          pricePreDiscount = {},
          priceVIP = {},
          custom = {},
        } = state;

        productAdapter.upsertMany(
          state.products,
          mergeStockAndProducts({
            products: products.map((product) => mergeProductTags(product)),
            price,
            stock,
            diff,
            ecopart,
            pricePreDiscount,
            priceVIP,
            custom,
          })
        );
        serializeProducts(state);
      }
    },

    [fetchProductsFromIndex.rejected]: (state, action) => {
      state.pending--;
    },
    [fetchProductById.pending]: (state, action) => {
      state.pending++;
    },
    [fetchProductById.fulfilled]: (
      state,
      {
        meta: {
          arg: { sku },
        },
        payload: { product, mergeProductTags },
      }
    ) => {
      state.pending--;
      state.loadedProducts[sku] = true;
      if (product) {
        productAdapter.upsertOne(state.products, mergeProductTags(product));
        serializeProducts(state);
      }
    },
    [fetchProductById.rejected]: (state, action) => {
      const {
        meta: {
          arg: { sku },
        },
      } = action;
      state.loadedProducts[sku] = true;
      state.pending--;
    },

    [resyncStock.pending]: (state, action) => {
      state.pending++;
    },
    [resyncStock.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        state.diffLive = data;
        const diff = data;
        Object.entries(diff).forEach(([sku, inStockDiff]) => {
          const product = state.products.entities[sku];

          if (product) {
            const { inStockBase, custom, price } = product;
            const inStock = Math.max(0, inStockBase - inStockDiff);
            const isAvailableForPurchase = isProductAvailableForPurchase({
              productCustoms: custom,
              price: price,
              inStock: inStock,
            });

            productAdapter.upsertOne(state.products, {
              sku,
              inStock,
              isAvailableForPurchase,
            });
          }
        });
      }
    },
    [resyncStock.rejected]: (state, action) => {
      state.pending--;
    },
    [fetchAllProductsFromAllCategories.fulfilled]: (state, action) => {
      state.fetchedAllProductsFromAllCategories = true;
    },
  },
});

const { actions, reducer } = slice;
export const {
  resetProducts,
  increasePendingLoadingAllProducts,
  decreasePendingLoadingAllProducts,
} = actions;
export default reducer;

export const {
  selectTotal: selectTotalProducts,
  selectAll: selectAllProducts,
  selectById: selectProductById,
} = productAdapter.getSelectors((state) => state.drive.products.products);

export const {
  selectTotal: selectTotalCategories,
  selectAll: selectAllCategories,
  selectById: selectCategoryById,
} = categoriesAdapter.getSelectors((state) => state.drive.products.categories);

export function selectGetProductsByFilter(state) {
  return ({ search, category, subcategorySlug }) =>
    selectAllProducts(state)
      .filter((product) => category === "*" || category === product.category)
      .filter(
        (product) =>
          !subcategorySlug || subcategorySlug === product.subcategorySlug
      )

      .filter(
        ({ title = "", subtitle = "", description = "" }) =>
          title.match(search) ||
          subtitle.match(search) ||
          description.match(search)
      );
}

export function selectIsCategoryLoaded(state, category) {
  return !!state.drive.products.loadedCategories[category];
}

export function selectPendingLoadingAllProducts(state) {
  return state.drive.products.pendingLoadingAllProducts;
}

export function selectIsBaseLoaded(state) {
  return !!state.drive.products.price;
}

export function selectStockedCategories(state) {
  return state.drive.products.stockedCategories;
}

export function selectIsProductDataLoaded(state, sku) {
  if (!sku) {
    return true; // never load without sku
  }

  const product = selectProductById(state, sku);
  const isBaseLoaded = selectIsBaseLoaded(state);

  if (isBaseLoaded && product && product.sku) {
    return true;
  }

  if (
    !isBaseLoaded ||
    !state.drive.products.loadedProducts[sku] ||
    state.pending
  ) {
    return false;
  }

  return true;
}

export function selectHasPendingRequests(state) {
  return !!state.drive.products.pending;
}

function isProductAvailableForPurchase({ productCustoms, inStock, price }) {
  const messageCode = getProductMessageCode({ custom: productCustoms });

  return (
    messageCode === messageCodeAvailable && inStock > getThresholdStock(price)
  );
}
