import axios from "axios";

import { backendHost } from "./constants";

const inProgress = {};
export async function axiosGetOne(url) {
  if (inProgress[url]) {
    return await inProgress[url];
  }
  inProgress[url] = (async () => {
    const response = await axios.get(`${backendHost}${url}`);
    if (
      response &&
      response.data &&
      response.data[0] === "<" &&
      response.data[1] === "!"
    ) {
      // this is NOT a 200 OK, this is a a HTML page from the single-web-page
      throw new Error("404 Not Found");
    }
    return response;
  })();

  try {
    return await inProgress[url];
  } finally {
    inProgress[url] = null;
  }
}

const cache = {};
export async function axiosGetOneCache(url) {
  const cached = cache[url];
  if (cached) {
    return cached;
  }
  return (cache[url] = await axiosGetOne(url));
}
