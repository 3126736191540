function getKeepParams(toPathname, search, keepParams) {
  const query = new URLSearchParams(search);
  const result = new URLSearchParams();
  keepParams.forEach((param) => {
    if (typeof param === "string" && query.has(param)) {
      result.set(param, query.get(param));
    } else if (
      typeof param === "object" &&
      query.has(param.param) &&
      param.routes.includes(toPathname)
    ) {
      result.set(param.param, query.get(param.param));
    }
  });
  return result.toString();
}

export default null;

export function getFinalURL({
  location,
  keep,
  to = location.pathname,
  search = location.search,
  params = {},
}) {
  const toUrl = new URL(to, `https://example.com${location.pathname}`);
  const { pathname: toPathname, search: toSearch } = toUrl;
  const toParams = new URLSearchParams(toSearch);
  const query = new URLSearchParams(
    // are we the same URL?
    toPathname === location.pathname
      ? // if so, keep all of our params
        search.substr(1)
      : // else, keep only the parameters to keep accross pages
        getKeepParams(toPathname, search, keep)
  );
  Array.from(toParams.entries()).forEach(([param, value]) => {
    query.delete(param);
    query.set(param, value);
  });
  Object.keys(params).forEach((param) => {
    const value = params[param];
    query.delete(param);
    if (value !== null) {
      query.set(param, value);
    }
  });
  const queryString = query.toString();
  return `${toPathname}${queryString ? `?${queryString}` : ""}`;
}

export function getUrlObject({
  location,
  keep,
  to = location.pathname,
  search = location.search,
  params = {},
}) {
  const toUrl = new URL(to, `https://example.com${location.pathname}`);
  const { pathname: toPathname, search: toSearch } = toUrl;
  const toParams = new URLSearchParams(toSearch);
  const query = new URLSearchParams(
    // are we the same URL?
    toPathname === location.pathname
      ? // if so, keep all of our params
        search.substr(1)
      : // else, keep only the parameters to keep accross pages
        getKeepParams(toPathname, search, keep)
  );
  Array.from(toParams.entries()).forEach(([param, value]) =>
    query.set(param, value)
  );
  Object.keys(params).forEach((param) => {
    const value = params[param];
    query.delete(param);
    if (value !== null) {
      query.set(param, value);
    }
  });
  const queryString = query.toString();
  return {
    pathname: toPathname,
    search: queryString ? `?${queryString}` : "",
  };
}
