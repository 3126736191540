import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { useSelector } from "react-redux";

import { fetchProductsFromIndex } from "./productSlice";
import { normalizeSearch } from "./search";

export const setSearch = createAsyncThunk(
  "drive/setSearch",
  async ({ search, storeId }, { getState, dispatch }) => {
    if (search) {
      const terms = normalizeSearch(search);
      if (terms[0]) {
        await dispatch(
          fetchProductsFromIndex({ index: terms[0].substr(0, 2), storeId })
        );
      }
    }
    return search;
  }
);

export const slice = createSlice({
  name: "search",
  initialState: {
    search: (() => {
      try {
        return new URLSearchParams(window.location.search).get("q");
      } catch (e) {}
    })(),
    modalOpen: false,
    activeTagFilters: [],
  },
  reducers: {
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    setActiveTagFilters: (state, { payload }) => {
      state.activeTagFilters = payload;
    },
  },
  extraReducers: {
    [setSearch.fulfilled]: (state, action) => {
      state.search = action.payload;
    },
  },
});

const { actions, reducer } = slice;
export const { setModalOpen, setActiveTagFilters } = actions;
export default reducer;

export function selectSearch(state) {
  return state.drive.nav.search;
}

export function selectModalOpen(state) {
  return state.drive.nav.modalOpen;
}

export function selectActiveTagFilters(state) {
  return state.drive.nav.activeTagFilters;
}

export function useActiveTagFilters() {
  return useSelector(selectActiveTagFilters);
}
