const theme = {
  breakpoints: {
    mobile: "640px",
    phablet: "640px",
    tablet: "768px",
    laptop: "1024px",
    desktop: "1280px",
    widescreen: "1536px",
  },
  colors: {
    primary: {
      100: "#FDE8ED",
      200: "#F9C7D4",
      300: "#F494AC",
      400: "#F06688",
      500: "#EB3361",
      600: "#DB1647",
      700: "#B9133C",
      800: "#940F30",
    },
    secondary: {
      100: "#FFF7C2",
      200: "#FFF08F",
      300: "#FFEA61",
      400: "#FFDD00",
      500: "#F5C800",
      600: "#E5B000",
      700: "#C28400",
      800: "#9E5F00",
    },
    tertiary: {
      100: "#E4F7ED",
      200: "#C4EED8",
      300: "#9CE3BF",
      400: "#6CD5A1",
      500: "#45C987",
      600: "#34B273",
      700: "#2D9A63",
      800: "#21734A",
    },
    interface: {
      100: "#F6F7F9",
      200: "#EDEFF2",
      300: "#CCD2DB",
      400: "#A5AFC0",
      500: "#808CA8",
      600: "#4E5774",
      700: "#282C3E",
      800: "#161723",
    },
    light: "#FFFFFF",
    white: "#FFFFFF",
    black: "#000000",
    infos: "#A5AFC0",
    success: "#45C987",
    warning: "#F5C800",
    error: "#DB1647",
    dark: "#323232",
    grey: {
      1: "#F4F4F4",
      2: "#E5E5E5",
      3: "#646464",
      4: "#A5A5A5",
      5: "#888888",
      6: "#A3A3A3",
      7: "#F2F2F2",
      8: "#BCBCBC",
      9: "#9D9D9D",
      mineShaftAlt: "#3A3A3A",
    },
    gray: {
      1: "#F4F4F4",
      2: "#E5E5E5",
      3: "#646464",
      4: "#A5A5A5",
      5: "#888888",
      6: "#A3A3A3",
      7: "#F2F2F2",
      8: "#BCBCBC",
      9: "#9D9D9D",
      mineShaftAlt: "#3A3A3A",
    },
    crimson: "#DB161E",
    silverChaline: "#A1A1A1",
  },
  fontFamily: "Montserrat, Helvetica, sans-serif",
  typography: {
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "20px",
      xl: "24px",
      "2xl": "32px",
      "3xl": "40px",
    },
    lineHeight: {
      xs: "20px",
      sm: "22px",
      md: "24px",
      lg: "28px",
      xl: "32px",
      "2xl": "40px",
      "3xl": "48px",
    },
    weight: {
      xs: {
        medium: 500,
        semibold: 600,
      },
      sm: {
        medium: 500,
        semibold: 600,
      },
      md: {
        medium: 500,
        semibold: 600,
      },
      lg: {
        medium: 500,
        semibold: 600,
      },
      xl: {
        medium: 500,
        semibold: 600,
      },
      "2xl": {
        medium: 500,
        semibold: 600,
      },
      "3xl": {
        medium: 500,
        semibold: 600,
      },
    },
  },
  navbarHeight: "50px",
  searchbarAnimationDuration: "200ms",
  drawerAnimationsDuration: 300,
  animation: {
    short: "200ms",
    medium: "300ms",
    long: "500ms",
  },
};

export function useStyledTheme() {
  return theme;
}

export default theme;
