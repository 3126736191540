import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { axiosGetOneCache } from "./api";
import { filterActiveContent } from "./cmsSlice";
import { formatStoreId } from "./constants";
import { selectProductById } from "./productSlice";

const giftCardOfferAdapter = createEntityAdapter({
  selectId: (x) => x.Id,
});

export const fetchAllOffers = createAsyncThunk(
  "drive/fetchAllOffers",
  async (_, { getState, dispatch }) => {
    //const state = getState();
    const now = new Date();
    const giftCardOffers = (
      await axiosGetOneCache(`/json/gift-card-offers.json`)
    ).data
      // sometimes, the Python data we have is not formatted correctly
      // using ISO-8601, which leads in gift card offers not working
      // correctly in Safari
      .map(({ StartAt, EndAt, ...other }) => ({
        ...other,
        ...(StartAt ? { StartAt: StartAt.toString().replace(/ /, "T") } : {}),
        ...(EndAt ? { EndAt: EndAt.toString().replace(/ /, "T") } : {}),
      }))
      .filter(
        // we must filter now because multiple gift cards might share the same Id
        ({ StartAt, EndAt }) =>
          new Date(StartAt) <= now && new Date(EndAt) >= now
      );
    return {
      giftCardOffers,
    };
  }
);

export const slice = createSlice({
  name: "offers",
  initialState: {
    pending: 0,
    giftCardOffers: giftCardOfferAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: {
    [fetchAllOffers.pending]: (state, action) => {
      state.pending++;
    },
    [fetchAllOffers.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        const { giftCardOffers } = data;
        giftCardOfferAdapter.upsertMany(state.giftCardOffers, giftCardOffers);
      }
    },
    [fetchAllOffers.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const { reducer } = slice;
export default reducer;

export const {
  selectTotal: selectTotalGiftCardOffers,
  selectAll: selectAllGiftCardOffers,
  selectById: selectGiftCardOfferById,
} = giftCardOfferAdapter.getSelectors(
  (state) => state.drive.offers.giftCardOffers
);

export function selectAllActiveGiftCardOffers(state, storeId) {
  return filterActiveContent({
    content: selectAllGiftCardOffers(state),
    storeId,
  });
}

export function selectGiftCardOffersForSku(state, sku, storeId) {
  const activeGiftCardOffers = selectAllActiveGiftCardOffers(state, storeId);
  return activeGiftCardOffers.filter(
    ({ EligibleProducts = [] }) =>
      EligibleProducts.indexOf(sku.toString()) >= 0 ||
      EligibleProducts.indexOf("*") >= 0
  );
}

export function selectGiftCardContents(state, cartContents, storeId, isVip) {
  storeId = formatStoreId(storeId);
  const giftCartContents = {};
  const cartPrice = Object.values(cartContents).reduce(
    (s, { count, price }) => s + count * (Number(price) || 0),
    0
  );

  Object.keys(cartContents)
    .filter((sku) => cartContents[sku].count)
    .forEach((sku) => {
      const product = selectProductById(state, sku);
      if (!product) {
        return;
      }
      selectGiftCardOffersForSku(state, sku, storeId).forEach(
        ({
          ProductId,
          ProductTitle,
          GiftCardAmount,
          UpsellConditions,
          UpsellTitle,
          UpsellValue,
        }) => {
          if (!giftCartContents[ProductId]) {
            giftCartContents[ProductId] = {
              faceValue: 0,
              title: ProductTitle,
              upsellConditions: UpsellConditions,
              upsellTitle: UpsellTitle,
              upsellValue: UpsellValue,
            };
          }

          // eslint-disable-next-line
          const calculateAmount = new Function(
            ["product", "cartContents", "cartPrice", "isVip"],
            `return ${GiftCardAmount}`
          );
          giftCartContents[ProductId].faceValue +=
            calculateAmount(product, cartContents, cartPrice, isVip) *
            cartContents[sku].count;
        }
      );
    });
  return giftCartContents;
}
