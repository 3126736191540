import { memo, useState, useEffect, useCallback, useMemo } from "react";

import { propagateFeatureFlagEvent } from "@mobsuccess-devops/gifi-api-abstraction";
import { useAtom, useSetAtom } from "@mobsuccess-devops/react-atom";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { useStores } from "../../../../features/drive/cmsSlice";
import {
  defaultPrepaymentAmount,
  defaultStoreId,
} from "../../../../features/drive/constants";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../../features/drive/localStorage";
import {
  resetProducts,
  fetchProductById,
} from "../../../../features/drive/productSlice";
import { useAllCartItems } from "../Cart";
import { useIsVoucherEnabledOnStore } from "../Voucher";

//#region current store hooks
const atomStoreId = "#Drive.StoreBase.storeId";
const atomPickedStore = "#Drive.StoreBase.pickedStore";

export function useGetCurrentStoreId() {
  const [storeId] = useAtom(atomStoreId, () => {
    const localStoreId = getLocalStorage("storeId");
    return localStoreId || defaultStoreId;
  });

  return storeId;
}

export function useGetCurrentStoreName() {
  const storeId = useGetCurrentStoreId();
  const getStoreName = useGetStoreName();

  return useMemo(() => {
    return getStoreName(storeId);
  }, [getStoreName, storeId]);
}

export function useGetCurrentStoreAddress() {
  const storeId = useGetCurrentStoreId();
  const getStoreAdress = useGetStoreAddress();

  return useMemo(() => {
    return getStoreAdress(storeId);
  }, [getStoreAdress, storeId]);
}

export function useGetCurrentStorePhone() {
  const storeId = useGetCurrentStoreId();
  const getStorePhone = useGetStorePhone();

  return useMemo(() => {
    return getStorePhone(storeId);
  }, [getStorePhone, storeId]);
}

export function useGetCurrentStorePrepaymentAmount() {
  const stores = useStores();
  const storeId = useGetCurrentStoreId();

  return useMemo(() => {
    return (
      stores?.find((x) => x.store_id === storeId)?.prepayment_amount ||
      defaultPrepaymentAmount
    );
  }, [stores, storeId]);
}

export function useSetCurrentStoreId() {
  const dispatch = useDispatch();
  const setAtom = useSetAtom();
  const cartItems = useAllCartItems();

  return useCallback(
    async (id) => {
      if (getLocalStorage("storeId") !== id) {
        await dispatch(resetProducts());
        setLocalStorage("storeId", id);
        setAtom(atomStoreId, id);
        setAtom(atomPickedStore, true);

        for (const sku of Object.keys(cartItems)) {
          await dispatch(fetchProductById({ sku, id }));
        }
      }
      return id;
    },
    [cartItems, dispatch, setAtom]
  );
}
//#endregion

//#region store details hooks
export function useGetStoreName() {
  const stores = useStores();
  return useCallback(
    (storeId) => {
      const store = stores?.find((x) => x.store_id === storeId);
      return store ? `${store.store_name}` : null;
    },
    [stores]
  );
}

export function useGetStoreAddress() {
  const stores = useStores();
  return useCallback(
    (storeId) => {
      const storeIdNumber = Number(storeId);
      storeId = storeIdNumber.toString();
      const store = stores?.find((x) => x.store_id === storeId);
      return !store
        ? null
        : {
            name: store.store_name,
            address1_full: store.address1_full,
            address2_full: store.address2_full,
            city_full: store.city_full,
            zip: store.zip,
          };
    },
    [stores]
  );
}

export function useGetStoreId() {
  const stores = useStores();
  return useCallback(
    (storeName) => {
      const store = stores?.find((x) => x.store_name === storeName);
      return store ? `${store.store_id}` : null;
    },
    [stores]
  );
}
//#endregion

//#region private store details hooks
function useGetStorePhone() {
  const stores = useStores();
  return useCallback(
    (storeId) => {
      return stores?.find((x) => x.store_id === storeId)?.telephone;
    },
    [stores]
  );
}
//#endregion

//#region track if a store has been set
export function usePickedStore() {
  const [pickedStore] = useAtom(atomPickedStore, () => {
    return getLocalStorage("storeId") ? true : false;
  });

  return pickedStore;
}
//#endregion

function StoreBase({ children }) {
  const [geolocInited, setGeolocInited] = useState(false);
  const setStoreId = useSetAtom();
  const [storeId] = useAtom(atomStoreId);
  const isStoreVoucherEnabled = useIsVoucherEnabledOnStore(storeId);

  useEffect(() => {
    propagateFeatureFlagEvent({
      flag: "gifi-ba100",
      isActive: isStoreVoucherEnabled,
    });
  }, [isStoreVoucherEnabled]);

  useEffect(() => {
    if (!geolocInited) {
      const params = new URLSearchParams(window.location.search);
      const paramStoreId = params.get("storeId") || params.get("store_id");
      if (paramStoreId && !paramStoreId.match(/^{/)) {
        setStoreId(atomStoreId, paramStoreId);
        setLocalStorage("storeId", paramStoreId);

        params.delete("storeId");
        params.delete("store_id");
        setTimeout(() =>
          window.history.replaceState(
            null,
            "",
            `${window.location.pathname}?${params.toString()}`
          )
        );
      }

      setGeolocInited(true);
    }
  }, [geolocInited, setStoreId]);

  return children;
}

StoreBase.propTypes = {
  children: PropTypes.node,
};

StoreBase.defaultProps = {
  children: null,
};

export default memo(StoreBase);
