import { useCallback, useRef, useEffect, useMemo } from "react";

import { isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import { useGetCurrentStoreId } from "../../components/Drive/SideEffects/Store";

import {
  messageCodeExcluded,
  messageCodeOutOfStock,
  maxCountPerItemInCart,
  messageCodeAvailable,
  thresholdStockTiers1,
  thresholdStockTiers2,
  thresholdStockTiers3,
} from "./constants";
import {
  selectProductById,
  selectIsProductDataLoaded,
  selectHasPendingRequests,
} from "./productSlice";
import { selectAllProducts, fetchProductById } from "./productSlice";

export function useProductDataLoaded(sku) {
  return useSelector(
    useCallback((state) => selectIsProductDataLoaded(state, sku), [sku])
  );
}

export function useProductNoAutoload(sku) {
  return useSelector(
    useCallback(
      (state) => {
        if (sku && sku.includes("operation-")) {
          return null;
        }
        return selectProductById(state, sku);
      },
      [sku]
    )
  );
}

export function useProduct(skuRaw, { forceLoad = false } = {}) {
  const sku = String(skuRaw);
  const dispatch = useDispatch();
  const product = useProductNoAutoload(sku);
  const forceLoadRef = useRef(forceLoad);
  const skuRef = useRef(sku);
  const storeId = useGetCurrentStoreId();
  if (forceLoad && skuRef.current !== sku) {
    skuRef.current = sku;
    forceLoadRef.current = forceLoad;
  }
  useEffect(() => {
    if ((!product || forceLoadRef.current) && sku && skuRaw) {
      forceLoadRef.current = false;
      dispatch(fetchProductById({ sku, storeId }));
    }
  }, [dispatch, product, sku, forceLoadRef, skuRaw, storeId]);
  return product;
}

export function useAllProducts() {
  return useSelector(selectAllProducts, isEqual);
}

export function useHasPendingRequests() {
  return useSelector(selectHasPendingRequests);
}

function getAvailabilityDate(product) {
  const dateString = product?.custom
    ?.find((x) => x.match(/^date_dispo:/))
    ?.replace(/^date_dispo:/, "")
    .replace(/(....)(..)(..)/, "$1-$2-$3");
  return dateString ? new Date(dateString).toLocaleDateString("fr-FR") : null;
}

function replaceProductMessageMacros({ text, product }) {
  return text
    ?.replace(/(%1|\$stock)/, Math.max(0, product.inStock))
    ?.replace(/(%2)/, getAvailabilityDate(product));
}

export function useProductDisableAddToCartMessage(sku) {
  const product = useProduct(sku);
  if (product === null) {
    return null;
  }
  return replaceProductMessageMacros({
    product,
    text: product?.tags
      .find((x) => x.match(/^DisableAddToCart:Message/))
      ?.replace(/.*?=/, ""),
  });
}

export function useProductDisableAddToCartClickMessage(sku) {
  const product = useProduct(sku);
  if (product === null) {
    return null;
  }
  return replaceProductMessageMacros({
    text: product.tags
      .find((x) => x.match(/^DisableAddToCart:ClickMessage/))
      ?.replace(/.*?=/, ""),
    product,
  });
}

export function useProductMessageCode(sku) {
  const product = useProduct(sku);
  return useMemo(() => getProductMessageCode(product), [product]);
}

export function useIsProductModalDisplayable(product) {
  const messageCode = getProductMessageCode(product);
  return useIsProductModalDisplayableForMessageCode(messageCode);
}

export function useIsProductModalDisplayableForMessageCode(messageCode) {
  return useMemo(() => {
    if (messageCode === null) {
      return false;
    }

    return ![messageCodeExcluded, messageCodeOutOfStock].includes(messageCode);
  }, [messageCode]);
}

export function useProductThresholdStock(priceRaw) {
  return useMemo(() => {
    return getThresholdStock(priceRaw);
  }, [priceRaw]);
}

export function useProductReachMaximum(
  inStock,
  thresholdStockApplied,
  skuCountItems
) {
  return useMemo(
    () =>
      (inStock > thresholdStockApplied &&
        inStock <= skuCountItems + thresholdStockApplied) ||
      skuCountItems >= maxCountPerItemInCart,
    [inStock, thresholdStockApplied, skuCountItems]
  );
}

export function getProductMessageCode(product) {
  if (!product) {
    return null;
  }

  if (product.custom) {
    const messageCode = product.custom.find((x) => x.match(/^message_code:/));
    if (messageCode) {
      return messageCode.replace(/^message_code:/, "");
    }
  }

  return messageCodeAvailable;
}

export function useGetThresholdStock(priceRaw) {
  return useMemo(() => {
    return getThresholdStock(priceRaw);
  }, [priceRaw]);
}

export function getThresholdStock(priceRaw) {
  const priceNum = Number(priceRaw);

  return priceNum < 1.5
    ? thresholdStockTiers1
    : priceNum < 5
    ? thresholdStockTiers2
    : thresholdStockTiers3;
}
