import React, { useContext } from "react";

const PlatformUrlBaseContext = React.createContext();

export function getEntryPointUrlBase() {
  if (process.env.NODE_ENV !== "production") {
    return "";
  }

  if (window.location.pathname.match(/^\/dashboard_new\/clickandcollect\//)) {
    return "/dashboard_new/clickandcollect";
  }
  if (window.location.pathname.match(/^\/dashboard_new\/advertising/)) {
    return "/dashboard_new/advertising";
  }
  if (
    window.location.hostname.match(/www.mobsuccess.com/) &&
    window.location.pathname.match(/\/dashboard_new\/clickandcollect/)
  ) {
    return "/dashboard_new/clickandcollect";
  }
  if (window.location.pathname.match(/\/components\//)) {
    return `/components/${window.location.pathname.split("/")[2]}`;
  }

  return "";
}

export function useComponentPathPrefix() {
  return process.env.NODE_ENV === "production" ? "/components" : "";
}

export function PlatformUrlBaseProvider({ children }) {
  const values = {
    base:
      process.env.NODE_ENV === "production"
        ? ""
        : "https://localhost_www.mobsuccess.com",
    entryPointBase: getEntryPointUrlBase(),
  };
  return (
    <PlatformUrlBaseContext.Provider value={values}>
      {children}
    </PlatformUrlBaseContext.Provider>
  );
}

export function usePlatformUrlBase() {
  return useContext(PlatformUrlBaseContext).base;
}

export function useEntryPointUrlBase() {
  return useContext(PlatformUrlBaseContext).entryPointBase;
}
